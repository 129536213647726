import React from 'react';
import "../css/Footer.css";

const Footer = () => {
    return (
        <footer className="footer-general">
            <div className="footer_01">
                <a href="https://apolosan.medium.com/reference-system-for-defi-e86342a650de"><i className="fab fa-medium-m"></i></a>
                <a href="https://twitter.com/systemdefi"><i className="fab fa-twitter"></i></a>
                <a href="https://t.me/rsdcash"><i className="fab fa-telegram-plane"></i></a>
                <a href="https://discord.gg/7gKSkvA8nR"><i className="fab fa-discord"></i></a>
                <a href="https://github.com/apolosan/rsd"><i className="fab fa-github"></i></a>
            </div>
            <div className="footer_02">
                <div>
                    <a href="/">Home</a>
                    <a href="/whitepaper">Whitepaper</a>
                    <a href="/code">Code</a>
                    {/* <a href="/stake">Stake</a> */}
                    <a href="/team">Team</a>
                    <a href="/crowdsale">Mint</a>
                </div>
                <p>© Copyright - {(new Date()).getFullYear()} - RefDAO</p>
            </div>
        </footer>          
    );
}

export default Footer;