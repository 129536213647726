import React from 'react';
import Footer from '../components/Footer';
import '../css/Whitepaper.css';

const Whitepaper = () => {
    return (
        <>
            <div className="whitepaper">
                <div className="code_link">
                    Download <a href="https://rsd.cash/rsd_whitepaper.pdf">here</a> or read it below
                </div>
                <iframe src="/rsd_whitepaper.pdf" width="100%" height="105%"></iframe>
            </div>
            <Footer />
        </>
    );
}

export default Whitepaper;