const Boxes = [
    {   id: 1,
        type: "info",
        classes: "box_01",
        iconClasses: "fas fa-brain",
        title: "Intelligent and dynamic",
        text: "Reference System for DeFi (RSD) is a ERC-20/BEP-20 token based on reinforcement learning, a modern technique that optimizes decision-making and helps to make the system design smarter."
    },
    {   id: 2,
        type: "info",
        classes: "box_02",
        iconClasses: "fas fa-cogs",
        title: "A completely algorithmic token",
        text: "Unlike conventional tokens, the RSD supply is totally controlled by an active algorithm sensitive to changes in its market demand."
    },
    {   id: 3,
        type: "info",
        classes: "box_03",
        iconClasses: "fas fa-money-bill-alt",
        title: "Pegged by itself",
        text: "Rather than having a high correlation with the most popular cryptocurrencies, RSD price is practically independent and dictated only by its market."
    },
    {   id: 4,
        type: "info",
        classes: "box_04",
        iconClasses: "fas fa-dollar-sign",
        title: "Great for trading!",
        text: "The RSD system design offers constant arbitrage opportunities. Traders can take advantage of this feature and bring even more benefits to the other users."
    },
    {   id: 5,
        type: "info",
        classes: "box_05",
        iconClasses: "fas fa-wallet",
        title: "Earn RSD tokens just by doing a single transfer!",
        text: "Basically, to control the supply every transaction charges or rewards its respective sender. In addition, to encourage and maintain its recurrent usage a random sender is regularly chosen to receive a prize."
    },
    {
        id: 6,
        type: "info",
        classes: "box_07",
        iconClasses: "fas fa-angle-double-right",
        title: "Enjoy 50% more",
        text: "Early adopters get 50% more tokens during the crowdsale. If you mint 100 RSD you will get 150 RSD. Don't miss this opportunity! It is for a limited time..."
    },
    {
        id: 7,
        type: "info",
        classes: "box_08",
        iconClasses: "far fa-gem",
        title: "Good for miners/validators",
        text: "Every RSD transaction rewards its respective miner/validator. This means that the RSD token becomes an interesting alternative to income from mining/validation on Layer 2."
    },
    {   id: 8,
        type: "info",
        classes: "box_06",
        iconClasses: "fas fa-rocket",
        title: "The future is now",
        text: "RSD is a novel type of cryptocurrency. One that allows users to contribute to the ecosystem and also receive in return. Be part of this technology and start right now!"
    }        
];

export default Boxes;