import React, { Component } from 'react';
import PriceQuery from './PriceQuery';
import '../css/Mint.css';

class Mint extends Component {

    constructor(props) {
        super(props);
    }

    clickMintHandler = () => {
        this.props.clickMint();
    }

    render() {
        return(
            <div className="mint">
                <div className="area_00">
                    <label className="network_name">{this.props.networkName}</label>
                </div>
                <div className="area_01">
                    <label className="label_title">Wallet Balance:</label> <label className="amount">{`${this.props.accountBalance} ${this.props.unity}`}</label>
                </div>
                <div className="area_02">
                    <label className="label_title">RSD Balance:</label> <label className="amount">{`${this.props.rsdBalance} RSD`}</label>
                </div>
                <div className="area_03">
                    <div></div><label className="label_title">Desired Amount:</label>
                </div>
                <div className="area_04">
                    <input id="inputRsd" type="text" onBlur={this.props.changeInputRsd} /> <label className="amount">RSD</label>
                </div>    
                <div className="area_05">
                    <input id="inputOriginal" type="text" onBlur={this.props.changeInputOriginal} /> <label className="amount">{this.props.unity}</label>
                </div>    
                <div className="area_06">
                    <div></div><button type="submit" onClick={this.clickMintHandler} className="confirm">Mint</button>
                </div>
                <div className="area_07">
                    {/* <PriceQuery web3={this.props.web3}
                    pairId={this.props.web3.currentProvider.chainId == 56 ? 1 : 0}
                    classTitle="label_title"
                    classAmount="amount" /> */}
                </div>
            </div>
        )
    }
}

export default Mint;