import { Container } from 'react-bootstrap';

const Layout = (props) => {
    return (
        <Container fluid style={{ padding: 0, margin: 0 }}>
            {props.children} 
        </Container>
    );
}

export default Layout;