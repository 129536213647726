import React from 'react';
import "../css/Goals.css";

const Goals = () => {
    return (
        <div className="goals">
            <h5 className="goals_title">RSD Token Goals:</h5>
            <ul className="goals_list">
                <li>Be the reference token on the DeFi market</li>
                <li>Incentivize and maintain the ecosystem</li>
                <li>Create value on the ecosystem for its stakeholders</li>
                <li>Strengthen manual and automated trading systems</li>
            </ul>
        </div>          
    );
}

export default Goals;