import React from 'react';
import "../css/Box.css";

const Box = (props) => {
    return (
        (props.type == "info") ?
        <div className={`box ${props.classes}`}>
            <div className={`title ${props.titleClasses}`}><i className={props.iconClasses}></i><h6>{props.title}</h6></div>
            <p>{props.text}</p>
        </div> :
        <div className={`box ${props.classes}`}>
            <div className={`title ${props.titleClasses}`}><i className={props.iconClasses}></i><h6 className={props.titleH6}>{props.title}</h6></div>
            <div className="box_content">{props.children}</div>
        </div>  
    );
}

export default Box;