import React from 'react';
import Footer from '../components/Footer';
import '../css/Code.css';

const Code = () => {
    return (
        <>
            <div className="code">
                <h1>Code</h1>
                <div className="code_link">
                    Available <a href="https://github.com/apolosan/rsd">here</a>
                </div>
                <img src="code.jpg"></img>
            </div>
            <Footer />
        </>
    );
}

export default Code;