import React from 'react';
import Footer from '../components/Footer';
import Goals from '../components/Goals';
import Box from '../components/Box';
import Boxes from '../data/Boxes';
import "../css/Home.css";

const Home = () => {
    return (
        <>
            <div className="home">
                <div className="slogan">
                    <h1>Good for you, good for everyone!</h1>
                </div>
                <div className="logo">
                    <img src="rsd_color.svg"></img>
                </div>
            </div>
            <div className="box_container">
                {Boxes.map(box => { return(
                    <Box key={box.id} type={box.type} classes={box.classes} iconClasses={box.iconClasses} title={box.title} text={box.text} />
                )})}
            </div> 
            <Goals />
            <Footer />           
        </>
    );
}

export default Home;