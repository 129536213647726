import React from 'react';
import Footer from '../components/Footer';
import TeamBox from '../components/TeamBox';
import Members from '../data/Team.json';
import '../css/Team.css';

const Team = () => {
    return (
        <>
            <div className="team">
                <h1>Team</h1>
                <div className="team_box_container">
                    {Members.map(box => { return(
                        <TeamBox key={box.id} classes={box.classes} image={box.image} iconClasses={box.iconClasses} title={box.title} text={box.text} link={box.link} />
                    )})}
                </div> 
            </div>
            <Footer />
        </>
    );
}

export default Team;