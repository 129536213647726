import React, { useState, useEffect } from 'react';

const Countdown = props => {

    const calculateTimeLeft = (time) => {
        let timeNow = (props.timeCreation + props.timeRemain) - Math.floor(Date.now() / 1000);
        return timeNow < 0 ? 0 : timeNow;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });

    return (
        <div className="countdown">
                Crowdsale ends in {Math.floor(timeLeft / (60*60*24))} day(s), {Math.floor((timeLeft / (60*60)) % 24)} hour(s), {Math.floor((timeLeft / 60) % 60)} minute(s) and {Math.floor(timeLeft % 60)} second(s)
        </div>
    );
}

export default Countdown;