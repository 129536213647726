import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import ValuePriceAPI from '../components/ValuePriceAPI';
import '../css/NavigationBar.css';

const NavigationBar = () => {
    return (
        <Navbar bg="light" expand="lg" className="navigation-bar">
            <Navbar.Brand><a href="/"><img src="/logo.svg" width="40" height="40" className="d-inline-block align-top"></img></a><h5 className="ml-3 d-inline-block align-bottom rsd">Reference System for DeFi</h5></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {/* <Nav.Link href="#" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}><PriceQuery /></Nav.Link> */}
                    {/* <Nav.Link href="#" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}><ValuePriceAPI /></Nav.Link> */}
                    <Nav.Link href="/" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}>Home</Nav.Link>
                    <Nav.Link href="/whitepaper" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}>Whitepaper</Nav.Link>
                    <Nav.Link href="/code" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}>Code</Nav.Link>
                    {/* <Nav.Link href="/stake" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}>Stake</Nav.Link> */}
                    <Nav.Link href="/team" className="rsd" style={{fontSize: '1.2em', color: '#002255ff', marginLeft: '0.8em'}}>Team</Nav.Link>
                    <Nav.Link href="/crowdsale" className="rsd" style={{fontSize: '1.2em', color: 'rgb(255, 150, 50)', marginLeft: '0.8em'}}>Mint</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>      
    );
}

export default NavigationBar;