import './css/App.css';
import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NavigationBar from "./components/NavigationBar";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './pages/Home';
// import Admin from './pages/Admin';
import Whitepaper from './pages/Whitepaper';
import Code from './pages/Code';
// import Stake from './pages/Stake';
import Team from './pages/Team';
// import Crowdsale from './pages/Crowdsale';
import CrowdMint from './pages/CrowdMint';
import NoMatch from './pages/NoMatch';
import Layout from './components/Layout';

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
          <Router>
            <Layout>
                <Switch>
                  <Route exact path="/" component={Home} />
                  {/* <Route path="/admin" component={Admin} /> */}
                  <Route path="/whitepaper" component={Whitepaper} />
                  <Route path="/code" component={Code} />
                  {/* <Route path="/stake" component={Stake} /> */}
                  <Route path="/team" component={Team} />
                  <Route path="/crowdsale" component={CrowdMint} />
                  <Route component={NoMatch} />
                </Switch>
            </Layout>
          </Router>
      </React.Fragment>
    );
  }
}

export default App;
