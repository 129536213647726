import React from 'react';
import "../css/Box.css";
import "../css/TeamBox.css";

const Box = (props) => {
    return (
        <div className={`${props.classes}`}>
            <img src={props.image}></img><h6>{props.title}</h6>
            <p>{props.text}<a href={props.link}> More about...</a></p>
        </div>
    );
}

export default Box;