import React, { useState, useEffect, useRef } from 'react';
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import Footer from '../components/Footer';
import Mint from '../components/Mint';
import Box from '../components/Box';
import Countdown from '../components/Countdown';
import chains from '../data/chains.json';
import ReferenceSystemDefi from '../contracts/ReferenceSystemDeFi.json';
import BigNumber from 'bignumber.js';
import '../css/Crowdsale.css';

const CrowdMint = props => {

    const [account, setAccount] = useState([null]);
    const [accountBalance, setAccountBalance] = useState(0);
    const [chainId, setChainId] = useState(0);
    const [connected, setConnected] = useState(false);
    const [forcedDisconnection, setForcedDisconnection] = useState(false);
    const [contractCreationTime, setContractCreationTime] = useState(1615573889);
    const [crowdsaleDuration, setCrowdsaleDuration] = useState(7889229);
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [decimals, setDecimals] = useState(18);
    const [message, setMessage] = useState('');
    const [networkName, setNetworkName] = useState('');
    const [popupTitle, setPopupTitle] = useState('Please confirm your transaction');
    const [rsd, setRsd] = useState(null);
    const [rsdBalance, setRsdBalance] = useState(0);
    const [web3, setWeb3] = useState(null);
    const [isWrongNetwork, setIsWrongNetwork] = useState(false);
    const [saleRate, setSaleRate] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [ethereumProvider, setEthereumProvider] = useState(typeof window.ethereum === 'object');

    const changeInputRsd = input => {
        input.target.value = input.target.value.replace(/\,/g, '.');
        let number = new BigNumber(input.target.value);
        let value = parseFloat(number / saleRate).toFixed(4);
        if (number <= 0 || isNaN(number.toNumber()))
            input.target.value = '';

        if (number.isGreaterThan(50000))
            input.target.value = parseFloat(50000).toFixed(4);

        document.querySelector("#inputOriginal").value = (value > parseFloat(accountBalance)) ? accountBalance : value;
        input.target.value = (value > parseFloat(accountBalance)) ? parseFloat(accountBalance * saleRate).toFixed(4) : input.target.value;

        if (input.target.value == '')
            document.querySelector("#inputOriginal").value = '';
    }

    const forceDisconnection = () => {
        setConnected(false);
        setForcedDisconnection(true);
    }

    const changeInputOriginal = input => {
        input.target.value = input.target.value.replace(/\,/g, '.'); 
        let number = new BigNumber(input.target.value);
        let value = parseFloat(number * saleRate).toFixed(4);

        if (number <= 0 || isNaN(number.toNumber()))
            input.target.value = '';

        if (number.isGreaterThan(accountBalance)) 
            input.target.value = parseFloat(accountBalance).toFixed(4);

        document.querySelector("#inputRsd").value = (value > parseFloat(50000) || value > parseFloat(accountBalance * saleRate)) ? parseFloat(accountBalance * saleRate).toFixed(4) : value;
        input.target.value = (value > parseFloat(50000) || value > parseFloat(accountBalance * saleRate)) ? parseFloat(accountBalance) : input.target.value;

        if (input.target.value == '')
            document.querySelector("#inputRsd").value = '';
    }

    const togglePopup = () => {
        setPopupTitle('Please confirm your transaction');
        setMessage('');
        document.getElementsByClassName("footer-general")[0].style = showPopup ? "" : "-webkit-filter: blur(2px); -moz-filter: blur(2px); -o-filter: blur(2px); -ms-filter: blur(2px); filter: blur(2px);";
        document.getElementsByClassName("navigation-bar")[0].style = showPopup ? "" : "-webkit-filter: blur(2px); -moz-filter: blur(2px); -o-filter: blur(2px); -ms-filter: blur(2px); filter: blur(2px);";
        document.getElementsByClassName("crowdsale")[0].style = showPopup ? "" : "-webkit-filter: blur(2px); -moz-filter: blur(2px); -o-filter: blur(2px); -ms-filter: blur(2px); filter: blur(2px);";
        setShowPopup(!showPopup);
    }

    const clickMintButtonHandler = async () => {
        if (web3 == null || !connected)
            forceDisconnection();
        
        if (saleRate == 0) {
            rsd.getSaleRate.call(function(error, result) {
                if (result != null)
                    setSaleRate(result);
            });
        } else {
            let value = new BigNumber(parseFloat(document.querySelector("#inputOriginal").value) * 10**decimals);
            if (!isNaN(value) && !showPopup) {
                togglePopup();
                web3.eth.sendTransaction({
                    from: web3.eth.accounts[0],
                    to: rsd.address,
                    value: value.toString(),
                    gas: '150000'},
                    function(error, result) {
                       if (error) {
                            if (!showPopup) 
                                togglePopup();
                            setPopupTitle('Please try again');
                            setMessage('Transaction error / rejected!');
                            setTimeout(forceDisconnection, 4000);
                       } else {
                            waitForReceipt(result, function(receipt) {
                                if (receipt != null) {
                                    rsd.balanceOf.call(web3.eth.accounts[0], function(error, result) {
                                        setRsdBalance((result / (10 ** 18)).toFixed(4));
                                    });
                                    web3.eth.getBalance(web3.eth.accounts[0], function(error, result) {
                                        setAccountBalance(((result.toNumber() / (10 ** decimals))).toFixed(4));
                                    });
                                    if (!showPopup)
                                        togglePopup();
                                    setPopupTitle('Congratulations');      
                                    setMessage('Transaction sucessful!');                         
                                } else {
                                    if (!showPopup)
                                        togglePopup();
                                    setPopupTitle('Sorry');    
                                    setMessage('Transaction error! Please try again.');
                                }
                            });
                            if (!showPopup) 
                                togglePopup();
                            setPopupTitle('Please wait confirmation');
                            setMessage('Transaction sent!');
                       }
                    });
            }
        }
    }

    const updateChain = async () => {
        if (window.ethereum != undefined) {
            const chain_id = await window.ethereum.request({method: 'eth_chainId'});
            setChainId(chain_id);
        }
    }

    const updateAccount = async () => {
        if (window.ethereum != undefined) {
            const accounts_ = await window.ethereum.request({method: 'eth_requestAccounts'});
            setAccount(accounts_);    
        } else {
            setConnected(false);
        }
    }

    // check what blockchain is
    const updateWeb3 = () => {
        try {
            chains.map(chain => {             
                if (chain.chainId == parseInt(chainId)) {
                    if (chain.contractAddress == undefined || chain.contractAddress == "")
                        throw 'Wrong network';
                    setCurrencySymbol(chain.nativeCurrency.symbol);
                    setNetworkName(chain.name);
                    web3.eth.getBalance(web3.eth.accounts[0], function(error, result) {
                        if (result != null)
                            setAccountBalance(((result.toNumber() / (10 ** chain.nativeCurrency.decimals))).toFixed(4));
                    });
                    let rsd = web3.eth.contract(ReferenceSystemDefi.abi).at(chain.contractAddress);
                    setRsd(rsd);
                    setIsWrongNetwork(false);
                    setDecimals(chain.nativeCurrency.decimals);
                    rsd.getSaleRate.call(function(error, result) {
                        if (result != null)
                            setSaleRate(result);
                    });
                    rsd.balanceOf.call(web3.eth.accounts[0], function(error, result) {
                        if (result != null)
                            setRsdBalance((result / (10 ** 18)).toFixed(4));
                    });
                    rsd.getCrowdsaleDuration.call(function(error, result) {
                        if (result != null)
                            setCrowdsaleDuration(result.toNumber());
                    });
                }
            });
        } catch(error) {
            setConnected(false);
            if (error == 'Wrong network')
                setIsWrongNetwork(connected);
        }
    }

    const waitForReceipt = (hash, cb) => {
        web3.eth.getTransactionReceipt(hash, function(err, receipt) {
          if (err) {
            cb(err);
          }
      
          if (receipt !== null) {
            // Transaction went through
            if (cb) {
              cb(receipt);
            }
          } else {
            // Try again in 1 second
            window.setTimeout(function () {
              waitForReceipt(hash, cb);
            }, 1000);
          }
        });
    }

    useEffect(() => {
        updateWeb3();
    }, [chainId]);

    useEffect(() => {
        if (connected && account == null)
            window.location.reload();
    }, [connected]);

    useEffect(() => {
        updateWeb3();
        if (account[0] != null && account[0] != undefined && web3.eth != null && web3.eth != undefined)
            setConnected(true);
    }, [account]);

    useEffect(() => {
        if (window.ethereum != undefined) {
            setWeb3(new Web3(window.ethereum));
            updateChain();
        } else {
            setWeb3(null);
        }
    }, [ethereumProvider]);

    useEffect(() => {
        if (forcedDisconnection)
            window.location.reload();
    }, [forcedDisconnection]);

    useEffect(async () => {
        const provider = await detectEthereumProvider();
        if (window.ethereum != undefined) {
            updateAccount();
            setShowPopup(false);
            setEthereumProvider(true);
            window.ethereum.on('chainChanged', chain_id => {
                updateChain();
                setShowPopup(false);
            });
            window.ethereum.on('disconnect', error => {
                updateChain();
                setShowPopup(false);
            }); 
            window.ethereum.on('streamDisconnect', error => {
                forceDisconnection();
            }); 
            window.ethereum.on('unlockStateChanged', error => {
                forceDisconnection();
            }); 
            window.ethereum.on('connect', connection_info => {
                updateAccount();
                setShowPopup(false);
            }); 
            window.ethereum.on('accountsChanged', accounts_ => {
                updateAccount();
                setShowPopup(false);
            });
        }
    }, []);

    let message_ = (window.ethereum == undefined) ? 'Please install Metamask or Trustwallet' : 'Please connect to Metamask or Trustwallet';
    let _return;
    _return = 
    <>
        {!connected || isWrongNetwork ?
        <>
            <div className="crowdsale">
                <div className="crowdsale_title">
                    <h1>Crowdsale Mint</h1>
                    <Countdown timeRemain={crowdsaleDuration} timeCreation={contractCreationTime} />
                </div>
                <div className="box_crowdsale">
                    <p><span>ATTENTION!</span> Do NOT invest what you can not afford to lose!</p>
                    <Box key="8" titleClasses="title_box" classes="crowdsale_box" type="info" title={`${isWrongNetwork ? 'Wrong network' : message_}`} />
                </div>
            </div>
            <Footer />     
        </>       
        : 
        <>
            {showPopup ?
            <Box key="8" title={popupTitle} classes="popup">
                <p>{message}</p>
                <button onClick={togglePopup} className="confirm">Close</button>
            </Box>
            : null}
            <div className="crowdsale">
                <div className="crowdsale_title">
                    <h1>Crowdsale Mint</h1>
                    <Countdown timeRemain={crowdsaleDuration} timeCreation={contractCreationTime} />
                </div>
                <div className="box_crowdsale">
                    <p><span>ATTENTION!</span> Do NOT invest what you can not afford to lose!</p>
                    <Box key="7" titleClasses="title_box" titleH6="address" classes="crowdsale_box" type="input" title={account} iconClasses="fas fa-shopping-cart">
                        <Mint
                            networkName={networkName} 
                            clickMint={clickMintButtonHandler}
                            unity={currencySymbol}
                            accountBalance={accountBalance}
                            account={account}
                            changeInputRsd={changeInputRsd}
                            changeInputOriginal={changeInputOriginal}
                            maxValue={accountBalance}
                            rsdBalance={rsdBalance}
                            web3={web3} />
                    </Box>
                </div>
            </div>
            <Footer />
        </>}
    </>
    return _return;
}

export default CrowdMint;